<template>
  <section
    id="recent-projects"
    class="overflow-hidden"
  >
    <v-row no-gutters>
      <v-col
        class="secondary text-center pa-5"
        cols="12"
        md="6"
      >
        <base-bubble-1
          style="transform: rotate(180deg) translateX(25%)"
        />

        <base-heading>
          Recent Projects
        </base-heading>

        <base-text class="mb-5">
        </base-text>

        <v-card color="secondary">
          <v-container class="pa-2">
            <v-row>
              <v-col
                v-for="project in projects"
                :key="project"
                cols="12"
                md="6"
              >
                <a href="#">
                  <v-img
                    :src="require(`@/assets/${project}.jpg`)"
                    max-height="300"
                  />
                </a>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <v-col
        class="flex-column justify-center"
        cols="12"
        md="6"
      >
        <v-row
          class="d-flex justify-space-between align-center"
        >
        <!-- <v-img
          :src="require('@/assets/color-boards960x1080.jpg')"
          height="100%"
        /> -->
        <v-col
          cols="1"
          class="flex-grow-0 flex-shrink-1"
        >
          <!-- <v-card></v-card> -->
        </v-col>
          <v-col
          cols="8"
          class="flex-grow-0 flex-shrink-0 mt-10"
        >
            <div
              class="fb-page align-self-center"
              data-href="https://www.facebook.com/streamlinepainting2020"
              data-tabs="timeline"
              data-width="500px"
              data-height="800px"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
              >
                <blockquote
                cite="https://www.facebook.com/streamlinepainting2020"
                class="fb-xfbml-parse-ignore"
                >
                  <a href="https://www.facebook.com/streamlinepainting2020">Streamline Painting</a>
                </blockquote>
            </div>
          </v-col>
          <v-col
            cols="1"
            class="flex-grow-0 flex-shrink-1"
          >
          <!-- <v-card></v-card> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'RecentProjects',

    data: () => ({
      projects: [
        'paint-start430x300',
        'rand-paintcans430x300',
        'paintglove430x300',
        'ladder-doors430x300',
      ],
    }),
  }
</script>
